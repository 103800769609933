/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import api from "../services/api";
import { getEmailDashboard } from "../services/storageEmailService";

const getChromebooksList = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = await getEmailDashboard();
      const html = await axios.get(
        `${api.getBaseURL()}/api/v1/chromebook?email=${email}`
      );
      resolve(html.data);
    } catch (error) {
      reject(error);
    }
  });
};

const getPaginateChromebooks = (
  page = 1,
  perPage = 10,
  unit = "all",
  group = "all",
  period = "all",
  search = ""
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = await getEmailDashboard();
      const html = await axios.get(
        `${api.getBaseURL()}/api/v1/chromebook/get-paginate?page=${page}&per_page=${perPage}&unit=${unit}&group=${group}&period=${period}&email=${email}&search=${search}`
      );
      resolve(html.data);
    } catch (error) {
      reject(error);
    }
  });
};

const processAll = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = await getEmailDashboard();
      const html = await axios.get(
        `${api.getBaseURL()}/api/v1/chromebook/process-all?email=${email}`
      );
      resolve(html.data);
    } catch (error) {
      reject(error);
    }
  });
};

const getProvisioned = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = await getEmailDashboard();
      const html = await axios.get(
        `${api.getBaseURL()}/api/v1/chromebook/provisioned?email=${email}`
      );
      resolve(html.data);
    } catch (error) {
      reject(error);
    }
  });
};

const getActive = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = await getEmailDashboard();
      const html = await axios.get(
        `${api.getBaseURL()}/api/v1/chromebook/active?email=${email}`
      );
      resolve(html.data);
    } catch (error) {
      reject(error);
    }
  });
};

const getRankingSchools = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = await getEmailDashboard();
      const html = await axios.get(
        `${api.getBaseURL()}/api/v1/chromebook/ranking-schools?email=${email}`
      );
      resolve(html.data);
    } catch (error) {
      reject(error);
    }
  });
};

const getPaginateRanking = (page = 1, perPage = 15) => {
  return new Promise(async (resolve, reject) => {
    try {
      const email = await getEmailDashboard();
      const html = await axios.get(
        `${api.getBaseURL()}/api/v1/chromebook/ranking-schools/paginate?page=${page}&per_page=${perPage}&email=${email}`
      );
      resolve(html.data);
    } catch (error) {
      reject(error);
    }
  });
};

export {
  getChromebooksList,
  getPaginateChromebooks,
  processAll,
  getProvisioned,
  getActive,
  getRankingSchools,
  getPaginateRanking,
};
