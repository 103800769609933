import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import logo from "../assets/images/computer.png";

const useStyles = makeStyles((theme) => ({
  paperChromeBook: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 71,
    backgroundColor: "#F8F8F8",
    borderRadius: 5,
    margin: '0 10px',
    marginBottom: 12,
  },
  chromebookItem: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
  },
  chromebookName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#233674",
  },
  chromebookSchool: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#484B4D",
  },
  chromebookNumSerie: {
    // flex: 1,
    minWidth: 200,
    height: 44,
    padding: "0 10px",
    color: "#fff",
    borderRadius: 5,
    backgroundColor: "#D8E5F8",
    border: "1px solid #3D7EDB",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chromebookNumSerieText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#233674",
    whiteSpace: "nowrap",
  },
  containerImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(54, 86, 191, 0.2)",
    height: 45,
    width: 45,
    borderRadius: 50,
    marginRight: 10,
  },
  computer: {
    opacity: 1,
  },
}));

const formatSchoolName = (name) => {
  if (name === "/") return "Não relacionado";
  return name.replace("/", "");
};

const ChromeBookItem = ({ item }) => {
  const classes = useStyles();
  return (
    <div className={classes.paperChromeBook}>
      <Grid container spacing={2}>
        <Grid
          className={classes.chromebookItem}
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
        >
          <Grid container spacing={1}>
            <div className={classes.containerImage}>
              <img src={logo} alt={"chromebook"} className={classes.computer} />
            </div>
            <Grid
              className={classes.chromebookItem}
              item
              xs={9}
              sm={10}
              md={9}
              lg={10}
              xl={11}
            >
              <div
                style={{
                  maxHeight: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <span className={classes.chromebookName}>
                  {item?.model || "Sem modelo"}
                </span>
                <br />
                <span className={classes.chromebookSchool}>
                  {formatSchoolName(item?.orgUnitPath)}
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
          <div className={classes.chromebookNumSerie}>
            <span className={classes.chromebookNumSerieText}>Cod.</span>
            <span className={classes.chromebookNumSerieText}>
              {item?.serialNumber}
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default ChromeBookItem;
